



















































































































































































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'HomeView',
})
export default class HomeView extends Vue {
  rating = 4
  inputRating = false

  tagCharOpen = '<'
  tagCharClose = '>'

  readonly skills = [
    'Vue.js (2, 3)',
    'Vue-router',
    'Vuex',
    'Typescript',
    'Git',
    'Gitlab CI/CD',
    'Webpack',
    'Quasar',
    'Bootstrap',
    'HTML5',
    'JavaScript',
    'REST API',
    'CSS/SCSS',
  ]

  readonly sideSkills = [
    'Golang',
    'Python',
    'Flask',
    'Django',
    'Electron',
    'C#',
    'Flutter',
    'SQL',
    'PGSQL',
    'Lua',
    'Tarantool',
    'Nginx',
    'PyQt',
  ]

  readonly links = [
    { label: 'Telegram', link: 'https://t.me/karanarqq' },
    { label: 'Github', link: 'https://github.com/sadfsdfdsa' },
    { label: 'Habr', link:'https://habr.com/ru/users/karanarqq/' },
    { label: 'Career', link: 'https://career.habr.com/karanarqq' },
    { label: 'VK', link: 'https://vk.com/karanarqq' },
    { label: 'Email', link: 'mailto:vartose@yandex.com' },
    { label: 'Medium', link: 'https://karanarqq.medium.com' },
  ]

  readonly examples = [
    { label: 'My Habr article', link: 'https://github.com/sadfsdfdsa/habratest' },
    {
      label: 'Filtery test case',
      link: 'https://github.com/sadfsdfdsa/bell-integrator-filtery',
    },
    {
      label: 'Vue3 test case',
      link: 'https://github.com/sadfsdfdsa/it_club_test_case',
    },
  ]

  get designText() {
    return this.inputRating ? 'Thanks!' : 'Do you like this minimal design?'
  }
}
