import { render, staticRenderFns } from "./HomeView.vue?vue&type=template&id=03e29e04&scoped=true&"
import script from "./HomeView.vue?vue&type=script&lang=ts&"
export * from "./HomeView.vue?vue&type=script&lang=ts&"
import style0 from "./HomeView.vue?vue&type=style&index=0&id=03e29e04&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03e29e04",
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QIcon,QSeparator,QChip,QCard,QRating});
